$dark-bg-blue: #080d12;
$light-blue: #9cb9d5;
$blue1: #374656;
.main-container{
  background-color: $dark-bg-blue;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  .info-container{
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .content{
      h1{
        font-family: lust, serif;
        font-weight: 700;
        font-style: normal;
        font-size: 5em;
        line-height: 0.8em;
        margin: 0.25em;
        animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
        animation-duration: 2s; /* don't forget to set a duration! */
      }
      .titles{
        font-family: forma-djr-banner, sans-serif;
        font-weight: 300;
        letter-spacing: 0.3em;
        font-size: 1.3em;
        span:hover{
          color: $light-blue;
        }
      }
      .links{
        a, a:hover, a:visited, a:active {
          color: inherit;
          text-decoration: none;
         }
        padding: 1em;
        display: flex;
        justify-content: center;
        .circle{
          margin: 0.5em;
          width: 2.2em;
          height: 2.2em;
          background-color: $blue1;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          -webkit-filter: brightness(100%);
          -webkit-transition: all 1s ease;
          -moz-transition: all 1s ease;
          -o-transition: all 1s ease;
          -ms-transition: all 1s ease;
          transition: all 1s ease;
        
        &:hover {
          cursor: pointer;
          -webkit-filter: brightness(150%);
          -webkit-transition: all 1s ease;
          -moz-transition: all 1s ease;
          -o-transition: all 1s ease;
          -ms-transition: all 1s ease;
          transition: all 1s ease;
        }
        &.fade-icon{
          -webkit-filter: brightness(50%);
          -webkit-transition: all 1s ease;
          -moz-transition: all 1s ease;
          -o-transition: all 1s ease;
          -ms-transition: all 1s ease;
          transition: all 1s ease;
        }
      }
        
      }
      text-align: center;
    }
    
  }
  .photo-container{
    width: 100%;
    height: 50vh;
    background-image: url("../public/profile.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
@media only screen and (min-width: 768px) {
  .main-container{
    flex-direction: row-reverse;
    height: 100vh;
    .info-container{
      width: 50%;
    }
    .photo-container{
      width: 50%;
      height: 100vh;
    }
  }
}